<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small12">
        <div class="bordered pad">
          <h3>
            Learners A-Z
          </h3>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small12">
        <div class="bordered pad">
          <ul>
            <li v-for="(learner, index) in learners" v-bind:key="index">
              <router-link v-bind:to="`/view-user/${learner.id}`">
                {{ learner.first_name }} {{ learner.last_name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'LearnersAZ',
  data() {
    return {
      learners: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
  },
  methods: {
    getLearners() {
      axios.get(`/users/getLearnersAz.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&course=${this.courseChosen}`)
        .then((response) => {
          this.learners = response.data.learners;
        });
    },
  },
  mounted() {
    this.getLearners();
  },
};
</script>
